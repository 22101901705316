<template>
  <div class="calculator-page delivery-calculator-page main-wrapper">
    <SSPHeader/>
    <main class="container">
      <section class="content">
        <div class="search_code">
          <SSPLoader v-if="isLoadingDadata" />
          <div>
            <h1>Калькулятор доставки</h1>
            <div class="fieldset">
              <div>
                <SSPSelector :placeholder="'Город отправки'" :value="sendData.from" @setValue="sendData.from = $event" :options="fromCities"/>
                <SSPSelector :placeholder="'Город получения'" :value="sendData.to" @setValue="sendData.to = $event" @input="searchCities" :options="prepareCities" :searchAction="true" />
              </div>
            </div><!--fieldset-->
            <!--fieldset-->
            <div class="actions">
              <div>
                <div class="radio_button_group">
                  <div class="radio_button_group_item">
                    <input id="radio-1" type="radio" v-model="sendData.type" value="t" checked>
                    <label for="radio-1">Шины</label>
                  </div>
                  <div class="radio_button_group_item">
                    <input id="radio-2" type="radio" v-model="sendData.type" value="w">
                    <label for="radio-2">Диски</label>
                  </div>
                </div>
                <div class="radio_button_group">
                  <div class="radio_button_group_item">
                    <input id="count-radio-1" type="radio" v-model="sendData.count" value="1">
                    <label for="count-radio-1">1шт</label>
                  </div>
                  <div class="radio_button_group_item">
                    <input id="count-radio-2" type="radio" v-model="sendData.count" value="2">
                    <label for="count-radio-2">2шт</label>
                  </div>
                  <div class="radio_button_group_item">
                    <input id="count-radio-3" type="radio" v-model="sendData.count" value="4">
                    <label for="count-radio-3">4шт</label>
                  </div>
                </div>
                <div class="dimensions">
                  <span><b>Объем:</b> {{ params[sendData.count].volume }}м<sup>3</sup></span>
                  <span><b>Вес:</b> {{ singlePlaceSize.weight * sendData.count}}кг</span>
                  <span v-if="sendData.type === 'w'">+ Обрешетка</span>
                </div>
              </div>
              <div>
                <button class="btn btn-submit" @click="calculate">Рассчитать</button>
              </div>
            </div><!--actions-->
            <div class="result" v-if="Object.keys(sortResult).length || isLoadingTK">
              <div class="trankomp-result-attention">К срокам добавляйте <b>1 сутки</b>, необходимые для сборки заказа и доставки до терминала ТК.</div>
              <table class="result_tab">
                <thead>
                  <tr>
                    <th>Компания</th>
                    <th>Стоимость</th>
                    <th>Срок доставки</th>
                    <th>Терминалы</th>
                  </tr>
                </thead>
                <tbody>
                <template
                    v-for="(item, index) in sortResult"
                    :key="index"
                >
                  <tr>
                    <td class="name_tk">
                      <span>Компания</span>
                      <div>
                        <div>
                          <img class="tk_logo" :src="'/img/transportcompanies/' + item.company + '.png'" alt="">
                          {{ companies[item.company] }}
                        </div>
                        <p v-if="storageLife[item.company]" v-html="'Бесплатное хранение ' + storageLife[item.company]"></p>
                        <strong class="priority" :class="'level-' + item.result.priority">{{ priorities[item.result.priority] }}</strong>
                      </div>
                    </td>
                    <td>
                      <span>Стоимость</span>
                      <p v-if="typeof item.result.realPrice !== 'undefined' && item.result.realPrice !== 0">
<!--                        {{ item.result.price }} <template v-if="Number.isInteger(item.result.price)">₽</template>-->
<!--                        <small v-if="!item.result.trustPrice" class="attention"><br><br>Требует уточнения</small>-->
                        <template v-if="vaxMode">Расчет ТК: {{ item.result.vaxPrice }} ₽<br>(Включая НДС)</template>
                        <template v-else>Расчет ТК: {{ item.result.realPrice }} </template>
                        <small v-if="item.result.coverPrice" class="attention"><br>+ обрешетка {{ item.result.coverPrice }} ₽</small>
                      </p>
                      <p v-else>-</p>
                    </td>
                    <td>
                      <span>Срок доставки</span>
                      <p v-if="typeof item.result.time !== 'undefined' && item.result.time !== 0 && typeof item.result.additionalDays?.days !== 'undefined' && item.result.additionalDays?.days !== 0">
                        {{ plural(item.result.time + item.result.additionalDays.days) }} <br>
                        <small>({{ item.result.additionalDays.str }})</small>
                      </p>
                      <p v-else-if="typeof item.result.time !== 'undefined' && item.result.time !== 0">{{ plural(item.result.time) }}</p>
                      <p v-else>-</p>
                    </td>
                    <td>
                      <span>Терминалы</span>
                      <ul v-if="item.result.terminals?.length">
                        <li
                            v-for="(item, index) in item.result.terminals"
                            :key="index"
                        >- {{ item }}</li>
                      </ul>
                      <p v-else>Терминалы не найдены</p>
                    </td>
                  </tr>
                  <template v-if="selfResult && item.company === 'self'">
                    <tr>
                      <td colspan="4">
                        <div style="position:relative;overflow:hidden;width: 100%;"><a href="https://yandex.ru/maps?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Яндекс Карты</a><a href="https://yandex.ru/maps/?ll=37.760378%2C55.775476&mode=usermaps&source=constructorLink&um=constructor%3A57239a348e82a8a2cc8d1cdf7f8a8fcb446bf1d71d73ae851dd78f2c4c930fe7&utm_medium=mapframe&utm_source=maps&z=8.67" style="color:#eee;font-size:12px;position:absolute;top:14px;">Яндекс Карты</a><iframe src="https://yandex.ru/map-widget/v1/?ll=37.760378%2C55.775476&mode=usermaps&source=constructorLink&um=constructor%3A57239a348e82a8a2cc8d1cdf7f8a8fcb446bf1d71d73ae851dd78f2c4c930fe7&z=9" height="400" frameborder="1" allowfullscreen="true" style="position:relative;width: 100%;"></iframe></div>
                      </td>
                    </tr>
                  </template>
                </template>
                  <tr v-if="isLoadingTK && !selfResult">
                    <td colspan="4" class="tb_loader">
                      <SSPLoader v-if="isLoadingTK && !selfResult" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </main>
    <SSPFooter/>
  </div>
</template>

<script>
import SSPHeader from "@/components/Header";
import SSPFooter from "@/components/Footer";
import SSPLoader from "@/components/Loader";
import SSPSelector from "@/components/Selector";
import {mapState} from "vuex";
import {actionTypes as dadataAction} from "@/store/modules/dadata";
import {actionTypes as deliveryAction} from "@/store/modules/delivery";

import {pluralDays} from '@/helpers/utils'

export default {
  name: 'SSPDeliveryCalculator',
  components: {
    SSPHeader,
    SSPFooter,
    SSPLoader,
    SSPSelector,
  },
  data(){
    return {
      fromCities: ['Москва', 'Санкт-Петербург'],
      companies: {
        self: 'Доставка Shinsale',
        kit: 'ТК Кит',
        pecom: 'ПЭК',
        dellin: 'Деловые линии',
        dpd: 'DPD'
      },
      storageLife: {
        kit: '6 дней',
        pecom: '',
        dellin: '2 дня. <br>Не считая дня прибытия и выходные',
        dpd: '14 дней'
      },
      sendData: {
        from: 'Москва',
        to: '',
        type: 't',
        count: 4
      },
      singlePlaceSize: {
        length: 0.75,
        width: 0.75,
        height: 0.25,
        weight: 15
      },
      params: {
        1: {
          volume: 0.14,
          count: 1
        },
        2: {
          volume: 0.28,
          count: 2
        },
        4: {
          volume: 0.56,
          count: 4
        }
      },
      priorities: {
        0: '',
        1: 'Средний приоритет',
        2: 'Высокий приоритет',
      },
      timer: null,
      vaxMode: process.env.VUE_APP_VAX_MODE || false
    }
  },
  computed: {
    ...mapState({
      isLoadingDadata: state => state.dadata.isLoading,
      isLoadingTK: state => state.delivery.isLoading,
      cities: state => state.dadata.cities,
      error: state => state.dadata.error,
      result: state => state.delivery.result,
      isLoggedIn: state => state.auth.isLoggedIn,
    }),
    prepareCities() {
      let prepareCities = []
      this.cities.forEach(function(city) {
          // prepareCities.push({label: city.data.city + ` (${city.data.region_with_type})`, value: city.data.city})
          prepareCities.push({label: city.data.city + ` (${city.data.region_with_type})`, value: city.data.city + ":" + city.data.region + ":" + city.data.city_kladr_id })
        }
      )
      return prepareCities
    },
    sortResult() {
      let array = Object.assign( {}, this.result)
      if(!this.selfResult) delete array.self
      array = Object.values(array)
      array.sort(function(x, y) {
        if(y.company === 'self') return 1
        if(x.company === 'self') return -1
        if(typeof x.result.priority !== 'undefined') return -1
        if(typeof y.result.priority !== 'undefined') return 1
        if(typeof x.result.priority !== 'undefined') return -1
        return y.result.priority - x.result.priority
      })
      return array
    },
    selfResult() {
      if(typeof this.result?.self?.result?.time !== 'undefined') {
        return true
      }
      return false
    }
  },
  methods: {
    async searchCities (searchCity) {
      if (searchCity.length > 2) {
        await this.$store.dispatch(dadataAction.getCities, searchCity)
      }
    },
    async calculate () {
      if (this.sendData.to !== '') await this.$store.dispatch(deliveryAction.getCompaniesData, { ...this.sendData, ...this.params[this.sendData.count], ...this.singlePlaceSize })
    },
    plural (num) {
      return pluralDays(num)
    }
  }
}
</script>
